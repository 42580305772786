var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _c("validation-observer", {
                    ref: "observer",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ invalid, handleSubmit }) {
                            return [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-card",
                                    {
                                      attrs: {
                                        header: "Dati Identificativi",
                                        "header-tag": "header",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-card-text",
                                        [
                                          _c("b-row", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "form-group col-md-3",
                                                staticStyle: { margin: "0" },
                                              },
                                              [
                                                _c("base-input", {
                                                  attrs: {
                                                    name: "title",
                                                    vid: "title",
                                                    label: "Titolo",
                                                    placeholder:
                                                      "Inserisci un titolo",
                                                    rules: { required: true },
                                                  },
                                                  on: {
                                                    keyup: _vm.onInputTitle,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form[_vm.rep].title,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.form[_vm.rep],
                                                        "title",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form[rep].title",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          _vm.form[_vm.rep]
                                                            .title &&
                                                          !_vm.validName,
                                                        expression:
                                                          "form[rep].title && !validName",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "custom-invalid-feedback",
                                                    staticStyle: {
                                                      width: "100%",
                                                      "margin-top": "0.25rem",
                                                      "font-size": "0.875em",
                                                      color: "#dc3545",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    Esiste già un'immagine salvata con lo stesso titolo. Per\n                    favore inserire un titolo differente per poter procedere\n                    al salvataggio.\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("b-table", {
                                            staticStyle: {
                                              "margin-top": "1rem",
                                            },
                                            attrs: {
                                              small: "",
                                              fields: _vm.fields,
                                              items: _vm.items,
                                              responsive: "sm",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "cell(filesystem)",
                                                  fn: function (data) {
                                                    return [
                                                      _c("b-form-file", {
                                                        attrs: {
                                                          state: Boolean(
                                                            data.item.file
                                                          ),
                                                          placeholder:
                                                            "Scegli un file o trascinalo qui...",
                                                          "drop-placeholder":
                                                            "Trascina il file qui...",
                                                          "browse-text":
                                                            "Naviga",
                                                        },
                                                        model: {
                                                          value: data.item.file,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              data.item,
                                                              "file",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "data.item.file",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                                {
                                                  key: "cell(delete)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            size: "sm",
                                                            variant: "lisaweb",
                                                            disabled: data.item
                                                              .file
                                                              ? false
                                                              : true,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              data.item.file =
                                                                null
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    Reset\n                  "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "py-2 mb-4" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            type: "button",
                                            disabled:
                                              invalid ||
                                              (_vm.form[_vm.rep].title &&
                                                !_vm.validName),
                                            variant: "lisaweb",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return handleSubmit(_vm.onSubmit)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n              Salva\n            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "lisaweb",
                                            size: "sm",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.resetForm()
                                            },
                                          },
                                        },
                                        [_vm._v("Reset")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      170486730
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }