<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <b-card header="Dati Identificativi" header-tag="header">
              <b-card-text>
                <b-row>
                  <div class="form-group col-md-3">
                    <base-input
                      name="title"
                      vid="title"
                      label="Titolo"
                      v-model="form[rep].title"
                      placeholder="Inserisci un titolo"
                      :rules="{ required: true }"
                    />
                  </div>
                </b-row>
                <b-table small :fields="fields" :items="items" responsive="sm">
                  <template #cell(filesystem)="">
                    <b-form-file
                      v-model="form[rep].image"
                      :state="Boolean(form[rep].image)"
                      placeholder="Scegli un file o trascinalo qui..."
                      drop-placeholder="Trascina il file qui..."
                      browse-text="Naviga"
                    ></b-form-file>
                  </template>
                  <template #cell(delete)="">
                    <b-button
                      size="sm"
                      variant="lisaweb"
                      @click="form[rep].image = null"
                      :disabled="form[rep].image ? false : true"
                    >
                      Reset
                    </b-button>
                  </template>
                </b-table>
              </b-card-text>
            </b-card>
            <div class="py-2 mb-4">
              <b-button
                @click="handleSubmit(onSubmit)"
                type="button"
                :disabled="invalid"
                variant="lisaweb"
                size="sm"
              >
                Salva
              </b-button>
              <b-button
                @click="$router.back()"
                variant="lisaweb"
                size="sm"
                class="float-right"
                ><b-icon-chevron-double-left
                  font-scale="0.9"
                ></b-icon-chevron-double-left
                >Torna indietro</b-button
              >
            </div>
          </b-form>
        </validation-observer>
      </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import LisaComunicaMixin from "@/mixins/LisaComunicaMixin";

export default {
  mixins: [FormMixin, ShortcutMixin, LisaComunicaMixin],
  data() {
    return {
      repository: "image",
      id: this.$route.params.id,
      form: {
        image: {
          title: null,
          image: null,
        },
      },
      fields: [
        {
          key: "filesystem",
          thStyle: {
            display: "none",
          },
          tdClass: "col-md-3",
        },
        {
          key: "delete",
          thStyle: {
            display: "none",
          },
          tdClass: "col-md-3",
        },
      ],
      items: [{ file: null }],
    };
  },
  components: {
    BaseIcon,
    BaseInput,
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.form[this.rep].image = this.items[0].file;
      let formData = new FormData();
      formData.append("title", this.form[this.rep].title);
      formData.append("image", this.form[this.rep].image);
      this.update(formData)
        .then(() => {
          this.isLoading = false;
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Immagine Modificata`,
          });
          this.shortcut("lisacomunica_template", null, "#Image", "filterImage");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
  },
  created() {
    this.show()
      .then((response) => {
        this.form[this.rep].title = response.data.content.title;
        this.form[this.rep].image = [response.data.content.url];
        this.isLoading = false;
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
        this.isLoading = false;
        this.ok = true;
      });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(tbody) {
  border-top: 0px !important;
}
:deep(table td) {
  border-top: 0px solid #dee2e6;
}
</style>
